// @flow
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Layout from '../components/layout';

export default () => (
  <Layout>
    <section
      className="module-header parallax bg-light-60 home"
      data-background="assets/images/module-8.jpg"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h1 m-b-20">Web, Mobile and Cloud Experts</h1>
            <p>Assisting you with your journey to the cloud</p>
          </div>
        </div>
      </div>
    </section>
    <section className="module module-divider-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="module-title text-center">
              <p className="title">
                With our unique blend of consultancy and development services,
                CS Frequency can give your project the kickstart it needs
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 m-auto">
            <div className="row">
              <div className="col-md-6">
                <div className="icon-box text-center">
                  <div className="icon-box-icon">
                    <i className="fas fa-users" />
                  </div>
                  <div className="icon-box-title">
                    <h6>Consultancy</h6>
                  </div>
                  <div className="icon-box-content">
                    <p>
                      Already have a team, but struggling with some direction?
                    </p>
                  </div>
                  <div className="icon-box-content">
                    <p>
                      Our consultants can complement your existing resources and
                      advise you on everything from cloud architecture and
                      migration strategy to mobile and web technologies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icon-box text-center">
                  <div className="icon-box-icon">
                    <i className="fas fa-toolbox" />
                  </div>
                  <div className="icon-box-title">
                    <h6>Development</h6>
                  </div>
                  <div className="icon-box-content">
                    <p>Looking for help building your next product?</p>
                  </div>
                  <div className="icon-box-content">
                    <p>
                      Whether it's web, mobile or full stack, our developers can
                      take your ideas from conception through to battle ready
                      products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="module module-divider-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="module-title text-center">
              <p className="title">
                With our extensive knowledge of cloud, web and mobile
                technologies, CS Frequency can deliver cutting edge end-to-end
                solutions
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="icon-box text-center">
              <div className="icon-box-icon">
                <i className="fas fa-mobile" />
              </div>
              <div className="icon-box-title">
                <h6>Web and Mobile</h6>
              </div>
              <div className="icon-box-content">
                <p>
                  We use the likes of React and React Native, coupled with our
                  knowledge of the cloud, to deliver state of the art scalable
                  solutions.
                </p>
              </div>
              <div className="icon-box-link">
                <GatsbyLink to="/web-mobile-development/">
                  Read more &rarr;
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="icon-box text-center">
              <div className="icon-box-icon">
                <i className="fas fa-cloud" />
              </div>
              <div className="icon-box-title">
                <h6>Cloud</h6>
              </div>
              <div className="icon-box-content">
                <p>
                  With our extensive experience using GCP, AWS and Azure, we can
                  help you re-architect and migrate your applications to the
                  cloud.
                </p>
              </div>
              <div className="icon-box-link">
                <GatsbyLink to="/cloud-development/">
                  Read more &rarr;
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="icon-box text-center">
              <div className="icon-box-icon">
                <i className="fas fa-box-open" />
              </div>
              <div className="icon-box-title">
                <h6>Our Products</h6>
              </div>
              <div className="icon-box-content">
                <p>
                  From open source libraries to commercial websites and
                  applications, we build our own products too.
                </p>
              </div>
              <div className="icon-box-link">
                <GatsbyLink to="/products/">Read more &rarr;</GatsbyLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
